@use '@angular/material' as mat;
@include mat.core();
$my-primary: mat.define-palette(mat.$green-palette, 500);
$my-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));
@include mat.all-component-themes($my-theme);

@import "./theme/font-awesome-pro/scss/fontawesome";
@import "./theme/font-awesome-pro/scss/regular";
@import "./../node_modules/quill/dist/quill.snow.css";

//$primary: #00CC61;
//$secondary: #004050;

quill-editor,
quill-view {
    @apply text-black;
}

quill-editor {
    .ql-editor {
        @apply min-h-96;
    }
}

.pulse {
    animation: blinker 1.5s linear infinite;
}

.tooltip {
    @apply invisible absolute;
}
.has-tooltip:hover .tooltip {
    @apply visible z-50
}
mat-card {
    mat-card-title {
        color: #666!important;
    }
    mat-card-content{
        color: #999!important;
    }
    mat-icon {
        display:none!important;
    }
}
  
@keyframes blinker {
    20% {
        background-color: rgba(0, 0, 0, 0);
    }
    45% {
        background-color: rgba(255, 0, 0, 0.2);
    }
    55% {
        background-color: rgba(255, 0, 0, 0.2);
    }
    80% {
        background-color: rgba(0, 0, 0, 0);
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Manrope', sans-serif;
}
.text-shadow-lg {
    text-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.3);
}
.no-text-shadow {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.text-ims-primary, .text-ims-primary-300 {
    @apply text-[#00CC61];
}
.bg-ims-primary, .bg-ims-primary-300 {
    @apply bg-[#00CC61];
}
.ring-ims-primary {
    @apply ring-[#00CC61];
}
.divide-ims-primary {
    @apply divide-[#00CC61];
}
.border-ims-primary, .border-ims-primary-300 {
    @apply border-[#00CC61];
}
.text-ims-secondary, .text-ims-secondary-800 {
    @apply text-[#004050];
}
.text-ims-secondary-light {
    @apply text-[#004050] opacity-50;
}
.bg-ims-secondary, .bg-ims-secondary-300 {
    @apply bg-[#004050];
}
.ring-ims-secondary {
    @apply ring-[#004050];
}
.divide-ims-secondary {
    @apply divide-[#004050];
}
.border-ims-secondary, .border-ims-secondary-300  {
    @apply border-[#004050];
}

.copy i{
    position: relative;
    user-select:none;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
}
.copy i:hover {
    transition-duration: 0.1s;
}
.copy i:after {
content: "";
    display: block;
    position: absolute;
    border-radius: 2em;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 20px #00CC61;
}
.copy i:active:after {
    box-shadow: 0 0 0 0 #00CC61;
    position: absolute;
    border-radius: 2em;
    left: 0;
    top:0;
    opacity: 1;
    transition: 0s;
}

@layer components {
    .full-calendar {
        h2 {
            @apply text-gray-800;
        }
        table {
            @apply text-gray-500;
        }
        @media not all and (min-width: 640px) { 
            h2 {
                font-size: 16px!important;
            }
            .fc-event-main {
                i, span.float-right {
                    display:none;
                }
            }
        }
    }
    .input {
        @apply bg-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 px-3;
    }
    .input-disabled {
        @apply cursor-not-allowed;
    }
    a.button-action,
    button.button-action {
        @apply bg-[#00CC61] hover:bg-green-600 transition-colors text-white py-2 px-4 rounded-lg float-right;
    }
    a.button-action-left,
    button.button-action-left {
        @apply bg-[#00CC61] hover:bg-green-600 transition-colors text-white py-2 px-4 rounded-lg;
    }
    button.button-action-primary {
        @apply bg-[#00CC61] hover:bg-green-600 transition-colors text-white py-2 px-4 rounded-lg;
    }
    button.button-action-secondary {
        @apply bg-white hover:bg-gray-100 transition-colors text-gray-500 py-2 px-4 rounded-lg border border-gray-200 float-right;
    }
    button.button-action-secondary-sm {
        @apply bg-white hover:bg-gray-100 transition-colors text-gray-500 py-1 px-2 rounded-lg border border-gray-200 float-right;
    }
    a.button-action-alternate,
    button.button-action-alternate {
        @apply bg-[#004050] hover:bg-gray-700 transition-colors text-white py-2 px-4 rounded-lg float-right;
    }
    a.button-action-blue,
    button.button-action-blue {
        @apply bg-blue-500 hover:bg-blue-500/80 transition-colors text-white py-2 px-4 rounded-lg float-right;
    }
    button.button-action-delete {
        @apply bg-red-500 hover:bg-red-600 transition-colors text-white py-2 px-4 rounded-lg float-right;
    }


    .back {
        @apply bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded inline-flex items-center float-left mr-5 mt-1;
    }
    h1 {
        small.subtle{
            @apply text-gray-400 text-lg font-thin;
        }
    }
    .card-content-delete {
        @apply mt-8;
    }
    button.delete-btn {
        @apply bg-red-500 hover:bg-red-600 transition-colors text-white py-2 px-4 rounded-lg float-right;
    }
    button.default-btn {
        @apply bg-gray-500 hover:bg-gray-600 transition-colors text-white py-2 px-4 rounded-lg float-right;
    }
    input[type="checkbox"]:disabled {
        @apply bg-gray-300;
    }
}
